import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationCheckService } from './authentication-check.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private router: Router, public auth: AuthenticationCheckService) { }
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let authToken = this.auth.isAuthenticated();
  //  let authToken =  window.localStorage.getItem('auth-token');
    let pagePath = state.url;
    let checkSignin = pagePath.search('signin');
    // console.table( [ {'Auth':authToken,'signin':checkSignin,'enter-password':checkOtp}]);
    // console.table( [ {'Auth':authToken,'checkSignin':checkSignin,'checkOtp':checkOtp, 'checkParticipate': checkParticipate, 'pagePath': pagePath}]);
    window.localStorage.setItem('pageUrl',state.url);
      if (authToken) {
          // logged in so return true
          if(checkSignin !== -1) return false;

          return true;
      }
        
          if(checkSignin !== -1) return true;

      // not logged in so redirect to login page with the return url and return false
      this.router.navigate(['signin']);
      return false;
  }
}
