import { Component } from '@angular/core';
import { ActivatedRoute, ActivationEnd, ActivationStart, NavigationEnd, Router } from '@angular/router';
import { AuthenticationCheckService } from './services/auth/authentication-check.service';
import { ApiConfiguration } from './services/http/api-configuration';
import { ApiService } from './services/http/api.service';
import { UploadZipService } from './services/upload-zip/upload-zip.service';
import { DataService } from './services/data/data.service';
import { DataLayerService } from './services/data-layer/data-layer.service';
import { ToastClientService } from './services/toast/toast-client.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isopenUploadBox = false;
  title = 'hubAdmin';
  uploadExcelDetails: any;
  token: any = String;
  nonce: any = String;
  dsa_id: any = String;
  // uid: any = String;

  loaderConfig = {
    "bgsColor": "rgba(52,51,51,0.19)",
    "bgsOpacity": 0.5,
    "bgsPosition": "bottom-right",
    "bgsSize": 60,
    "bgsType": "ball-scale-multiple",
    "blur": 5,
    "delay": 0,
    "fastFadeOut": true,
    "fgsColor": "#007bff",
    "fgsPosition": "bottom-right",
    "fgsSize": 60,
    "fgsType": "square-jelly-box",
    "gap": 24,
    "logoPosition": "center-center",
    "logoSize": 120,
    "logoUrl": "",
    "masterLoaderId": "master",
    "overlayBorderRadius": "0",
    "overlayColor": "rgba(40, 40, 40, 0.8)",
    "pbColor": "#007bff",
    "pbDirection": "ltr",
    "pbThickness": 3,
    "hasProgressBar": true,
    "text": "",
    "textColor": "#FFFFFF",
    "textPosition": "center-center",
    "maxTime": -1,
    "minTime": 300
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private auth: AuthenticationCheckService,
    private dataService: DataService,
    private _dataLayerService: DataLayerService,
    private toast : ToastClientService,
    private location : Location
  ) {

    this.router.events.subscribe(event => { // subscribe to router events
      if (event instanceof NavigationEnd) {
        this._dataLayerService.logPageView(event.url) //call our dataLayer service's page view method to ping home with the url value.
      }
    });



    this.activatedRoute.queryParams.subscribe(params => {
      console.log(params)
      if (params.token) {
        this.token = params.token
        this.nonce = params.nonce 
        this.dsa_id = params.dsa_id
        localStorage.setItem('redirect_url', params.redirect_url)

        this.getUserPoints()
      }
    });
  }

  getUserPoints() {
    console.log(this.token)
    console.log(encodeURIComponent(this.token))
    this.apiService.get(this.apiConfig.authentication + `?dsa_id=${this.dsa_id}&signature=${encodeURIComponent(this.token)}&nonce=${this.nonce}`)
      .subscribe((res: any) => {
        console.log(res)
        if(res.data.dsa_orgid == '' || res.data.dsa_orgid == null){
          this.toast.Error('Invalid User')
          return
        }
        res.data.program_id = 1
        localStorage.setItem('auth-token', res.data.token)
        localStorage.setItem('dsa_orgid', res.data.dsa_orgid)
        localStorage.setItem('login-details', JSON.stringify(res.data))
        this.updateBlancePoints(res.data.points)
        this.auth.saveAuth(res.data.token).then((resonse) => {
          this.router.navigate(["catalogue"]);
        })

      }, err => {
        console.log(err, 'asdsad')
      })
  }

  updateBlancePoints(points) {
    this.dataService.changePointData(points)
  }
}








