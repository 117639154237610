/* tslint:disable */
import { Injectable } from "@angular/core";

/**
 * Global configuration for Api services
 */
@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {
  // Staging  

  // baseUrl2 = 'https://appapi.roca.bigcityvoucher.co.in/v1/';
  baseurl3 = 'https://bhlappapi.bhl.bigcityvoucher.co.in/v1/';
  // Prod
  // baseurl3 = 'https://bhlappapi.bigcityexperience.com/web/v1/'

  //  baseurl3 = 'http://192.168.1.50/boilerplate/bhl_app_api/web/v1/'

  getUserPoints = this.baseurl3 + 'basic/get-user-profile'
  products = this.baseurl3 + 'product/list-product'
  addToCart = this.baseurl3 + 'agent/savecart'
  getCartDetails = this.baseurl3 + 'basic/list-cart'
  categories = this.baseurl3 + 'product/list-product-cats?flag'
  getOTP = this.baseurl3 + 'users/generate-otp'
  checkout = this.baseurl3 + 'basic/orders'
  deleteProductInCart = this.baseurl3 + 'agent/deletecartbyprod'
  redemptionSummary = this.baseurl3 + 'basic/redemption-summary'
  dashboardDetails = this.baseurl3 + 'rewards/userdashboard'
  pointsSummary = this.baseurl3 + 'agent/pointsummary'
  clearCart = this.baseurl3 + 'agent/clearcart'


  // CashPayout 
  // cashPayout = 'https://bhlappapi.bhl.bigcityvoucher.co.in/v1/agent/getpayoutdetails?dsa_orgid=90e838b6-4d55-4482-a452-1eaf133f3b6e'

  cashPayout = this.baseurl3 + "agent/getpayoutdetails"
  rejectRecord = this.baseurl3 + "agent/rejectpayout"
  approveAllLeads = this.baseurl3 + "basic/approve-all-leads"
  invoiceView = this.baseurl3 + 'basic/get-invoice-details'
  agentPoints = this.baseurl3 + 'basic/agent-points-summary'
  uploadInvoice = this.baseurl3 + 'agent/uploadinvoice';
  authentication = this.baseurl3 + "agent/authenticatedsa";
  readRecovery = this.baseurl3 + "agent/readrecovery"
}
export interface ApiConfigurationInterface {

}
