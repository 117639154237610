import { Component, OnInit } from '@angular/core';
import { ApiConfiguration } from 'src/app/services/http/api-configuration';
import { ApiService } from 'src/app/services/http/api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthenticationCheckService } from 'src/app/services/auth/authentication-check.service';
import { ToastClientService } from 'src/app/services/toast/toast-client.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  loginForm;
  loginDetails
  errorMessage: Boolean = true
  constructor(
    private apiService: ApiService,
    private apiConfig: ApiConfiguration,
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthenticationCheckService,
    private toast: ToastClientService
  ) {
    this.checkLogin();
    this.loginForm = formBuilder.group({
      username: ['', Validators.compose([
        Validators.required
      ])],
      password: ['', Validators.compose([
        Validators.required,
        Validators.minLength(6)
      ])],
    });
    this.loginForm.valueChanges
      .subscribe(data => {

      });
  }

  ngOnInit() {
    // this.testApi();
    this.loginDetails = JSON.parse(localStorage.getItem('login-details'))

  }

  checkLogin() {
    let previousURL = localStorage.getItem("previousURL")
    let login = localStorage.getItem("login")
    if (login == 'true') {
      this.router.navigateByUrl(previousURL)
    }
  }



  submit(value){
    console.log(value)
    if(value == ""){
      this.toast.Error("please enter dsa org id")
      return
    }
    let loginDetail = {"program_id":1}
    localStorage.setItem("dsa_orgid" ,value)
    localStorage.setItem("login-details" ,JSON.stringify(loginDetail))
    this.router.navigateByUrl("/catalogue/account")


  }
  login(formData) {
    // debugger
    // localStorage.setItem("user_name", ('user_name'));
          // localStorage.setItem("user_id", ('2'));
          // localStorage.setItem('login-details', JSON.stringify({"auth-token": "eyakjhsdf"}))
    // this.router.navigate(["catalogue"]);

  }
  isValid(field): boolean {
    let isValid: boolean = false;

    // If the field is not touched and invalid, it is considered as initial loaded form. Thus set as true
    if (this.loginForm.controls[field].touched == false) {
      isValid = true;
    }
    // If the field is touched and valid value, then it is considered as valid.
    else if (this.loginForm.controls[field].touched == true && this.loginForm.controls[field].valid == true) {
      isValid = true;
    }
    return isValid;
  }
}


// testApi(){
//   this.apiService.get(this.apiConfig.baseUrl2)
//   .subscribe((res:any)=>{
//     console.log('success', res)
//   },err=>{
//     console.log('failed', err);
//   })
// }
