import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  // cart product update
  private cartSource = new BehaviorSubject<any>('0')
  cartData = this.cartSource.asObservable();
  
  // Points update
  private pointSource = new BehaviorSubject<any>('0')
  pointData = this.pointSource.asObservable()

  private searchSource = new BehaviorSubject<any>('')
  searchData = this.searchSource.asObservable()

  ngDoCheck(){
  }

  constructor(
    
    ) { 
      
    }

  changeCartData(data:any){
    this.cartSource.next(data)
  }

  changePointData(data:any){
    this.pointSource.next(data)
  }

  changeSearchData(data:any){
    this.searchSource.next(data)
  }

}
